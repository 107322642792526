const productionConfig = {
  baseUrl: "https://lma-backend-api-production.a0584.prd.hc.de",
};

const stagingConfig = {
  baseUrl: "https://lma-backend-api-staging.a0584.prd.hc.de",
};

const developmentConfig = {
  baseUrl: "https://lma-backend-api-development.a0584.prd.hc.de",
};

const defaultConfig = {
  baseUrl: "http://localhost:9000",
};

const environments = {
  production: productionConfig,
  staging: stagingConfig,
  development: developmentConfig,
};

const hosts = {
  // GCP
  "lma-backoffice-ui-development.a0584.prd.hc.de": "development",
  "lma-backoffice-ui-staging.a0584.prd.hc.de": "staging",
  "lma-backoffice-ui-production.a0584.prd.hc.de": "production",
};

const hostname = window.location.hostname;
const environment = hosts[hostname] || "development";

export default environments[environment];
